import axios from 'axios';
import classNames from 'classnames';
import { CPTCodeRecord } from 'lib/server-utils/nexus-api';
import { DevError, DevLog } from 'lib/utils/logging-utils';
import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { XMarkIcon } from '@heroicons/react/24/solid';
const fetchCPTCodes = async (
  cptCodeOrLongDescription: string
): Promise<{ data: CPTCodeRecord[]; error: boolean }> => {
  DevLog('CptCodeOrLongDescription ===>', cptCodeOrLongDescription);

  try {
    // Do the search
    const ajaxCPTResult = await axios.get<CPTCodeRecord[]>(
      '/api/nexus/priorauth?' +
        new URLSearchParams([['cptCodeOrLongDescription', cptCodeOrLongDescription]])
    );
    DevLog('ajaxCPTResult', ajaxCPTResult);

    return { data: ajaxCPTResult?.data ?? [], error: false };
  } catch (error) {
    DevError('Error on fetchCPTCodes:', error);

    return { data: [], error: true }; // Return an error flag for handling in UI
  }
};

// types and interfaces
interface PriorAuthProcedureCodeLookupProps {
  searchIcon: string | undefined;
  handleCptCodeAndDescription: (
    cptCode: string,
    expirationDate: string,
    effectiveDate: string,
    comments: string
  ) => void;
  handleCptCodeChange: (value: string) => void;
  errorMessage: string | undefined;
  disableAllFields: boolean;
  clear: boolean;
  onApiError: (error: boolean) => void;
  ghostText: string;
}

export type ProcedureCodeResultItem = {
  cptCode: string;
  category: string;
  subCategory: string;
  description: string;
  longDescription: string;
  effectiveDate: string;
  expirationDate: string;
  comments: string;
  descriptor: string;
  ruleCount: string;
};

const PriorAuthProcedureCodeLookup: React.FC<PriorAuthProcedureCodeLookupProps> = ({
  searchIcon,
  handleCptCodeAndDescription,
  handleCptCodeChange,
  errorMessage,
  disableAllFields,
  clear,
  onApiError,
  ghostText,
}) => {
  const [val, setVal] = useState('');
  const [cptCode, setCPTCode] = useState<CPTCodeRecord[]>([]);
  const [loading, setLoading] = useState(false);

  const [showNoRecordsFound, setShowNoRecordsFound] = useState(false);
  const [itemSelected, setItemSelected] = useState<boolean | null>(null);
  const [showError, setShowError] = useState(false);

  // Reset the value when clear is triggered
  useEffect(() => {
    if (clear) {
      clearInput();
    }
  }, [clear]);

  // Function to clear the input
  const clearInput = () => {
    setVal('');
    setCPTCode([]);
    setShowError(false);
    setShowNoRecordsFound(false);
    setItemSelected(null);
    handleCptCodeAndDescription('', '', '', '');
    handleCptCodeChange('');
  };

  useEffect(() => {
    setShowNoRecordsFound(false);
    onApiError(false); // Reset error state on new search

    if (val && val.length > 2 && !itemSelected) {
      setLoading(true);
      setCPTCode([]);

      fetchCPTCodes(val).then((response) => {
        if (response.error) {
          onApiError(true); // Set error state if API fails
        } else {
          //const filteredCpt: CPTCodeRecord[] = Array.isArray(response) ? response : [];
          DevLog('data===>', response.data);
          setCPTCode(response.data);
          setLoading(false);
          setShowNoRecordsFound(true);
        }
        setLoading(false);
      });
    } else {
      setCPTCode([]);
    }
  }, [val]);

  const handleBlur = () => {
    if (!itemSelected || val.trim() === '') {
      setShowError(true); // Show error if no valid selection is made
    }
  };

  //Front End UI
  return (
    <>
      <div className="relative flex items-center h-[56px] w-full lg:w-[483px] autocomplete-wrapper">
        <Autocomplete
          value={val}
          items={cptCode}
          getItemValue={(item) => {
            const currentItem = item as ProcedureCodeResultItem;
            return `${currentItem?.cptCode} - ${currentItem?.longDescription}`;
          }}
          shouldItemRender={(item, val) => {
            const currentItem = item as ProcedureCodeResultItem;
            const isItemToRender =
              currentItem.cptCode?.toUpperCase().includes(val?.toUpperCase()) ||
              currentItem.longDescription?.toUpperCase().includes(val?.toUpperCase());
            return isItemToRender;
          }}
          renderMenu={(items) => (
            <div
              className={classNames(
                'dropdown',
                'text-gray-darkest',
                'border',
                'border-gray',
                'w-full', // Ensure the dropdown width matches the parent
                'md:max-h-[348px]',
                'max-h-[494px]',
                'indent-[16px]',
                'my-[12px]',
                'rounded-[4px]',
                'shadow-[0px_2px_8px_0px_rgba(0,0,0,0.08)]',
                'mt-0',
                'mb-0',
                'indent-0',
                'z-30'
              )}
            >
              <div className={classNames('text-gray-darkest', 'rounded-[4px]')}>
                {loading ? (
                  <div className="item">Loading...</div>
                ) : items.length === 0 && showNoRecordsFound && !itemSelected ? (
                  <div className="item">No matching results</div>
                ) : (
                  items
                )}
              </div>
            </div>
          )}
          renderItem={(item, isHighlighted) => {
            const currentItem = item as ProcedureCodeResultItem;
            if (currentItem) {
              return (
                <div
                  key={currentItem.cptCode}
                  className={`item ${isHighlighted ? 'selected-item' : ''}`}
                >{`${currentItem?.cptCode} - ${currentItem?.longDescription}`}</div>
              );
            } else return <></>;
          }}
          onChange={(_event, val) => {
            setItemSelected(false);
            handleCptCodeAndDescription('', '', '', '');
            setVal(val);
            handleCptCodeChange(val);
            setShowError(false);
          }}
          onSelect={(val, item) => {
            handleCptCodeAndDescription(
              item.cptCode,
              item.expirationDate,
              item.effectiveDate,
              item.comments
            );
            setVal(val);
            setItemSelected(true);
            setShowNoRecordsFound(false);
            setShowError(false);
          }}
          inputProps={{
            placeholder: ghostText,
            className: classNames(
              'w-full p-[16px] h-[56px] pr-[40px] rounded-[4px] border-2 bg-white focus:border-blue focus:bg-white priorauth-input',
              'truncate overflow-hidden whitespace-nowrap',
              showError ? '!border-error' : 'focus:!border-blue'
            ),
            onBlur: handleBlur,
            disabled: disableAllFields,
          }}
        />
        {/* Clear Button (X Icon) */}
        {val && !disableAllFields && (
          <button
            type="button"
            className="absolute right-4 top-1/2 transform -translate-y-1/2  text-gray-medium hover:text-black"
            onClick={clearInput}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}

        {/* Search Icon (Only shown when input is empty) */}
        {!loading && !val && (
          <img
            src={searchIcon}
            className="absolute top-1/2 transform -translate-y-1/2 cursor-pointer right-4 h-[20px] w-[20px]"
          />
        )}
      </div>

      <div
        className={classNames(
          'min-h-[24px]',
          showError === true ? 'text-error visible' : 'invisible'
        )}
      >
        {errorMessage || 'Enter a valid keyword or CPT code'}
      </div>
    </>
  );
};

export default PriorAuthProcedureCodeLookup;
